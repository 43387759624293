<template>
  <div>


    <div class="border-t border-b border-gray-200 mt-4  bg-white p-4">

      <answer-item  v-for="(question, index) in questions" :serial="index+1" :question="question" :key="question.id"></answer-item>

    </div>

  </div>
</template>

<script>
import answerItem from './components/answer_item.vue';

export default {
  name: "exam-answers",
  components: {answerItem},
  props: { examId: {required:  true}, participantId: { required:  true } },
  data: {
    questions: []
  },
  methods: {
    // questionOption( question ){
    //   return question.options || [];
    // }
  },
  created( ) {

    this.$store.dispatch( 'exam/answers', {
      params: {
        participant_id:this.participantId,
        exam_id:this.examId
      },
      success: ({data}) => {
        this.questions = data.questions;

      },
      error: () => {
        //this.$router.push( { path: '/my/courses' });
      }
    }).finally( () => this.$emit( 'loaded' ) )


  }
}
</script>

<style scoped>

</style>