<template>
	<div  :key="question.participantExamId"
				class="pt-1 mt-4 mb-10">

		<div v-html="serial+'. &nbsp;'+question.questionTitle" class="mt-2 font-bold text-gray-800 flex"></div>

		<div v-for="opt in options"
					class="rounded shadow my-2 px-4 py-3 flex justify-between"
					:class="question.isCorrect && question.correctAnswer == opt.serial ? 'bg-green-200':(
							question.isCorrect == false && question.givenAnswer == opt.serial ? 'bg-red-200'
						:(
									question.correctAnswer == opt.serial ? 'bg-green-100':''
							)
					)"
					:key="opt.serial"
		>
			<div v-html="opt.title" class="html-text"></div>
			<div>
				<div v-if="question.correctAnswer == opt.serial"></div>

				<div v-if="question.isCorrect == false && question.givenAnswer == opt.serial">
					Incorrect
				</div>
				<div v-else-if="question.isCorrect && question.correctAnswer == opt.serial">Correct</div>

			</div>
		</div>

		<div class="tabs">
			<button class="tab-item" v-if="question.discussion" @click="discussionOpen = !discussionOpen">Discussion</button>
			<button class="tab-item" v-if="question.reference" @click="referenceOpen = !referenceOpen">Reference</button>
		</div>

		<div v-show='discussionOpen'>
			<div class="border border-gray-300 p-2 py-3 relative html-text" v-html="question.discussion"></div>
		</div>

		<div v-show='referenceOpen'>
			<div class="border border-gray-300 p-2 py-3 relative html-text" v-html="question.reference" ></div>
		</div>
	
	</div>
</template>


<script>
export default {
  data: {
		discussionOpen: false,
		referenceOpen: false,
	},

	props: { 
		question: { 
			type: Object, default: {} 
		} ,
		serial: { type: Number }
	},

	computed: {
		options(){
			return this.question.options || {}
		}
	},

	methods: {
		
	},
}
</script>

<style scoped>
  .tabs {
    @apply w-full flex
  }

  .tabs .tab-item {
    @apply px-3 py-1 m-1 bg-gray-200 rounded-t
  }
</style>